.navbar-default .navbar-nav > li > a {
  font-size: 13px;
  color: white;
  font-weight: 200;
}

.navbar-default button {
  background-color: transparent;
  border-color: transparent;
  position: relative;
  top: 20px;
}

.navbar-default .navbar-toggle {
  border-color: white;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #d9d9d9;;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a {
  transition: 0.6s;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: $colorGreen;
  transition: 0.6s;
}

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.navbar-default .navbar-nav > li.dropdown > a:focus, .navbar-default .navbar-nav > li.dropdown > a:hover {
  color: white;
}

.navbar-right .dropdown-menu {
  right: -1px;
  left: auto;
  top: 48px;
}

.dropdown-menu > li > a {
  padding: 12px 18px;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

.navbar-toggle {
  margin-right: 5px;
}

#navigation {
  margin: auto 19%;
}

.navbar-default .navbar-nav > li > a {
  font-size: 13px;
  color: white;
  font-weight: 200;
}

.navbar-default {
  background-color: transparent;
  border-color: transparent;
  z-index: 99999;
}

.navbar-default .navbar-toggle {
  border-color: #d6d8d6;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #d9d9d9;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a {
  transition: 0.6s;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: $colorPurple;
  transition: 0.6s;
}

.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.dropdown-menu > li > a {
  color: white;
}

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.navbar-default .navbar-nav > li.dropdown > a:focus, .navbar-default .navbar-nav > li.dropdown > a:hover {
  color: white;
}

.navbar-default.navbar-fixed {
  position: fixed;
  background-color: $colorGreen;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  border-radius: 0;
}

.navbar-right .dropdown-menu {
  right: -1px;
  left: auto;
  top: 48px;
}

.dropdown-menu > li > a {
  padding: 12px 18px;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

.navbar-toggle {
  margin-right: 5px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

ul.nav.navbar-nav.navbar-right {
  padding: 12px 20px 12px 15px;
  margin-top: 0;
}

.navbar-fixed ul.nav.navbar-nav.navbar-right {
  margin-top: 0;
}

.navbar-fixed .navbar-toggle {
  margin-top: 0;
}

.navbar-default .navbar-toggle {
  border-color: white;
}

div#navbar {
  margin-top: 28px;
}

.navbar-fixed div#navbar {
  margin-top: 0px;
}

.nav .fb {
  position: relative;
  top: -6px;
}

.nav {
  z-index: 999;
}

ul.dropdown-menu {
  background: $colorGreen;
  font-size: 13px;
}