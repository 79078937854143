.aboutPageTop {
  background: url(../../img/bg-about.jpg) center center no-repeat;
  background-size: cover;
  height: 450px;
  position: relative;
  h1 {
    font-size: 50px;
    font-family: now_altlight, sans-serif;
    bottom: 15px;
    position: absolute;
    left: 15%;
    span {
      font-family: 'now_altbold', sans-serif;
    }
  }
}

.aboutPage {
  margin: 40px auto;
  .boss {
    font-family: $fontOpen;
    color: #2b2b2b;
    font-size: 15px;
    h2 {
      font-size: 22px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
  .signature {
    margin: 30px auto;
  }
  .img, .signature {
    img {
      object-fit: cover;
      height: auto;
      max-width: 100%;
    }
  }
  .text {
    margin-right: 5%;
    p {
      margin-top: 15px;
      text-align: justify;
      text-indent: 20px;
    }
  }
  .team {
    margin: 40px auto;
    h3 {
      font-size: 30px;
      font-family: now_altlight, sans-serif;
      span {
        font-family: 'now_altbold', sans-serif;
      }
    }
    .box {
      margin: 20px auto;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  /* Common style */
  .box figure {
    position: relative;
    float: left;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    margin: 0;
  }

  .box figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
  }

  .grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .grid figure figcaption::before,
  .grid figure figcaption::after {
    pointer-events: none;
  }

  .grid figure figcaption,
  .grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* Anchor will cover the whole item by default */
  /* For some effects it will show as a button */
  .grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
  }

  .grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
  }

  .grid figure h2 span {
    font-weight: 800;
  }

  .grid figure h2,
  .grid figure p {
    margin: 0;
  }

  .grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
  }
  /*-----------------*/
  /***** Goliath *****/
  /*-----------------*/

  figure.effect-goliath {
    background: $colorPurple;
    margin-bottom: 50px;
  }

  figure.effect-goliath img,
  figure.effect-goliath h2 {
    transition: transform 0.35s;
  }

  figure.effect-goliath img {
    backface-visibility: hidden;
  }

  figure.effect-goliath h2,
  figure.effect-goliath p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;
    text-align: center;
  }

  figure.effect-goliath p {
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0, 50px, 0);
    color: white;
    font-size: 21px;
    padding-top: 10px;
    width: 98%;
    text-transform: uppercase;

  }

  figure.effect-goliath:hover img {
    transform: translate3d(0, -80px, 0);
  }

  figure.effect-goliath:hover h2 {
    transform: translate3d(0, -100px, 0);
  }

  figure.effect-goliath:hover p {
    opacity: 1;
    transform: translate3d(0, 20px, 0);

  }
}
