.jobPage {
  margin: 50px auto;
  h2 {
    font-size: 30px;
    text-transform: uppercase;
    font-family: now_altlight, sans-serif;
    span {
      font-family: 'now_altbold', sans-serif;
    }
  }
  .box {
    margin: 50px auto 40px;
    width: 340px;
    height: 100%;
    border: 1px solid $colorGreen;
    text-align: center;
    padding: 15px 0;
    font-family: 'now_altbold', sans-serif;
    h3 {
      font-size: 20px;
      color: #2b2b2b;
      text-transform: uppercase;
    }
    h4 {
      font-size: 17px;
      text-transform: uppercase;
      color: $colorPurple;
      a {
        color: grey;
        font-size: 13px;
        font-weight: 200;
        &:hover {
          color: black;
        }
      }
    }
  }
  .requirements {

    position: relative;
    font-family: $fontOpen;
    font-size: 15px;
    background: url(../../img/bg-requirements.jpg) center center no-repeat;
    background-size: cover;
    min-height: 548px;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
    ul {
      margin: auto auto auto 20%;
      padding-top: 40px;
      padding-bottom: 20px;
      width: 40%;
      li {
        list-style: none url(../../img/icon-ok-purple.png);
        padding-bottom: 10px;
        span {
          position: relative;
          left: 3px;
          top: -1px;
        }
        &:first-child {
          position: relative;
          right: 20px;
          font-family: 'now_altbold', sans-serif;
          font-weight: bold;
          list-style: none;
          text-transform: uppercase;
        }
      }
    }
    .title {
      position: relative;
      text-align: center;
      top: 30px;
      font-family: 'now_altbold', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      width: 100px;
      margin: auto;
      padding-right: 38%;
    }
  }
  .object {
    position: absolute;
    top: -140px;
    right: 16%;
    img {
      max-width: 100%;
    }
  }
  .more {
    .title {
      font-size: 15px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    a {
      color: black;
    }
    p {
      font-family: $fontOpen;
      margin-top: 10px;
      b {
        font-weight: bold;
      }
    }
  }
  .text {
    font-family: $fontOpen;
  }
}