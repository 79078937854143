.blogPageTop {
  background: url(../../img/bg-blog.jpg) center center no-repeat;
  background-size: cover;
}

.blogPage {
  .pagination {
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    position: relative;
    ul {
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        border-radius: 50px;
        border: none;
        padding-top: 9px;
        height: 35px;
        width: 35px;
        margin-right: 6px;
        transition: 0.5s;
        &:hover {
          background-color: $colorGreen;
          transition: 0.5s;
          a {
            color: white;
          }
        }
        a {
          padding: 10px;
          color: #98a1a2;
        }
      }
      .active {
        background-color: $colorGreen;
        a {
          color: white;
          padding: 10px;
        }
      }
    }
    img {
      width: auto;
    }
    .left {
      position: absolute;
      top: 7px;
      left: 0;
      width: 30px;
      text-align: center;
      margin: auto;
      right: 180px;
    }
    .right {
      position: absolute;
      top: 6px;
      right: -168px;
      text-align: center;
      margin: auto;
      width: 20px;
      left: 0;
    }
  }
  .blogSection.singleNews {
    border-bottom: 1px solid #cecece;
    padding-bottom: 30px;
  }
  .blogSection .date span:first-child {
    font-size: 46px;
    line-height: 34px;
    margin-top: 11px;
  }
  .blogSection .date span:nth-child(2) {
    font-size: 10px;
  }
  .blogSection .date span:nth-child(3) {
    font-size: 20px;
  }
  .content {
    font-family: $fontOpen;
    margin-bottom: 50px;
    p {
      margin-top: 15px;
      font-size: 14px;
      text-align: justify;
    }
    h2 {
      text-align: justify;
    }
    span {
      display: block;

      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
    }
    img {
      width: 100%;
    }
  }
  .news-navigation {
    margin: 50px auto;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'now_altbold', sans-serif;
    .right {
      float: right;
    }
    .left {
      float: left;
    }
    .left, .right {
      font-weight: 800;
      display: inline-block;
      a {
        color: $colorGreen;
        padding: 15px 26px 10px;
        border-radius: 30px;
        border: 1px solid $colorGreen;
        background: transparent;
        transition: 0.3s;
        display: block;
        &:hover {
          background: $colorGreen;
          color: white;
          transition: 0.3s;
        }
      }
    }
  }
}