.topSection {
  background: url(../../img/bg-main-top.jpg) center center no-repeat;
  background-size: cover;
  height: 900px;
  .nav {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 30px;
  }
  .logo {
    position: relative;
    top: 11px;
    width: 200px;
    z-index: 9999;
  }
  .navbar-fixed .logo img {
    width: 73%;
  }
  .view {
    position: absolute;
    top: 50%;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
  }
  h1 {
    text-transform: uppercase;
    font-size: 35px;
    font-family: now_altlight, sans-serif;
    padding: 0;
    margin: 0;
    color: white;
    b {
      display: block;
      margin-top: 5px;
      font-family: 'now_altbold', sans-serif;
    }
  }
  .actions {
    font-family: now_altlight, sans-serif;
    text-align: center;
    position: relative;
    top: 50px;
    a {
      font-family: 'now_altbold', sans-serif;
      text-transform: uppercase;
      padding: 23px 35px 19px;
      border-radius: 30px;
      color: white;
      font-size: 16px;
      &:hover {
        text-decoration: none;
      }
      &:first-child {
        transition: 0.5s;
        background-color: $colorGreen;
        margin-right: 30px;
        &:hover {
          background-color: white;
          color: $colorGreen;
          transition: 0.5s;
        }

      }
      &:last-child {
        background-color: transparent;
        border: 1px solid white;
        transition: 0.5s;
        &:hover {
          background-color: $colorGreen;
          transition: 0.5s;
          border: 1px solid transparent;
        }
      }
    }
  }
  .top-nav {
    position: absolute;
    right: 3%;
    top: 30px;
    z-index: 999999;
    ul {
      list-style: none;
      margin: 0;
      li {
        margin-bottom: 20px;
        display: inline-block;
        a {
          text-transform: uppercase;
          padding: 8px 20px 6px;
          border-radius: 20px;
          color: white;

          @media all and (max-width: 700px) {
            padding: 8px 14px 6px;
          }
          &:hover {
            text-decoration: none;
          }
        }
        &:first-child {
          a {
            background-color: $colorPurple;
            margin-right: 10px;
            transition: 0.5s;
            &:hover {
              background-color: white;
              color: $colorPurple;
              transition: 0.7s;
            }
          }
        }
        a {
          background-color: $colorGreen;
          transition: 0.7s;
          &:hover {
            background-color: white;
            color: $colorGreen;
            transition: 0.7s;
          }
        }
      }
    }
  }
  .overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(22, 24, 22, 0.21), rgba(148, 154, 151, 0)) center;
  }
}

.aboutSection {
  border-bottom: 1px solid #cecece;
  margin: 70px auto;
  padding-bottom: 70px;
  font-family: $fontOpen;
  .title {
    color: #2b2b2b;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .description {
    font-size: 15px;
    .team {
      margin-top: 20px;
      margin-right: 20px;
      display: inline-block;
      a {
        font-family: 'now_altbold', sans-serif;
        text-transform: uppercase;
        padding: 18px 27px 14px;
        border-radius: 27px;
        color: white;
        font-size: 16px;
        background: $colorGreen;
        transition: 0.3s;
        border: 1px solid $colorGreen;
        &:hover {
          color: $colorGreen;
          background: white;
          border: 1px solid $colorGreen;
          transition: 0.3s;
        }
      }
    }
    .fb {
      margin-top: 20px;
      display: inline-block;
    }
  }
  .logo {
    margin-top: 20px;
    margin-bottom: 34px;
  }
  .counters {
    top: 50px;
    position: relative;
    .experience {
      position: absolute;
      font-size: 25px;
      font-family: 'nowalt-regular', sans-serif;
      top: -70px;
      right: 210px;
      b {
        font-family: 'now_altbold', sans-serif;
        font-weight: 800;
        position: absolute;
        top: 30px;
        right: -268px;
        font-size: 35px;
        text-transform: uppercase;
      }
      span {
        text-transform: uppercase;
        font-weight: 800;
        font-family: $fontOpen;
        color: #a30691;
        font-size: 77px;
        position: relative;
        &:nth-child(2) {
          font-size: 25px;
          font-family: now_altlight, sans-serif;
          position: relative;
          top: -32px;
          color: $colorBlack;
          font-weight: 200;
        }
      }
    }
    .department {
      font-family: 'now_altbold', sans-serif;
      font-size: 25px;
      position: absolute;
      top: 12px;
      right: 60px;
      text-transform: uppercase;
      span {
        font-weight: 800;
        font-family: $fontOpen;
        color: #a30691;
        font-size: 77px;
      }
    }
    .partner {
      position: absolute;
      top: 135px;
      right: 110px;
      font-family: 'now_altbold', sans-serif;
      font-weight: 800;
      font-size: 25px;
      text-transform: uppercase;
      span {
        text-transform: none;
        display: block;
        font-weight: 800;
        font-family: $fontOpen;
        color: #a30691;
        text-align: right;
      }
    }
  }
  .col-sm-6 {
    margin-bottom: 30px;
  }
}

.whySection {
  padding-bottom: 80px;
  text-align: center;
  .title {
    text-transform: uppercase;
    color: #2b2b2b;
    font-size: 20px;
    margin: 10px auto;
    font-family: 'now_altbold', sans-serif;
  }
  .description {
    margin: auto 20%;
    font-family: $fontOpen;
    font-size: 13px;
  }
}

.opinionsSection {
  background: fixed url(../../img/bg-opinions.jpg) center center no-repeat;
  background-size: cover;
  min-height: 387px;
  overflow: hidden;
  .title {
    font-family: 'now_altbold', sans-serif;
    position: relative;
    top: 100px;
    font-size: 30px;
    color: white;
    text-align: center;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    color: white;
    margin: 0;
    text-align: center;
    position: relative;
    top: 160px;
    font-size: 18px;
    font-family: $fontOpen;
    padding: 0 5%;
    li {
      margin-bottom: 15px;
      span {

        &:before {
          content: '“';
        }
        &:after {
          content: '“';
        }
        position: relative;
        top: -4px;
        left: 10px;
      }
      .author {
        font-weight: bold;
        margin-top: 5px;
      }
    }
  }
  .slick-vertical .slick-slide {
    height: 400px;

    @media all and (max-width: 500px) {
      font-size: 14px;
    }
  }
}

.activitiesSection {
  margin: 40px auto;
  .titleBox {
    width: 100%;
    height: 66px;
    text-align: center;
  }
  .header {
    font-family: 'now_altbold', sans-serif;
    text-transform: uppercase;
    font-size: 30px;
    color: $colorBlack;
    text-align: center;
    margin-bottom: 40px;
  }
  .title {
    font-family: now_altlight, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    font-size: 25px;
    color: $colorBlack;
    text-align: center;
    b {
      font-family: 'now_altbold', sans-serif;
    }
  }
  .hours {
    display: inline-block;
    border: 2px solid $colorPurple;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    position: relative;
    left: 0;
    top: -30px;
    font-family: 'now_altbold', sans-serif;
    span {
      color: $colorPurple;
      position: relative;
      top: 28px;
      font-size: 25px;
      left: 0;
      font-weight: bold;
      line-height: 22px;
    }
  }
  ul {
    padding-left: 17px;
    margin: 40px 5% 20px 0;
    line-height: 22px;
    list-style: none url(../../img/icon-ok-purple.png);
    li {
      margin-bottom: 10px;
      font-family: $fontOpen;
      font-size: 13px;
    }
  }
  .checkout {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    a {
      font-family: 'now_altbold', sans-serif;
      text-transform: uppercase;
      padding: 18px 27px 14px;
      border-radius: 27px;
      color: white;
      font-size: 16px;
      background-color: $colorGreen;
    }
  }
  .second {
    position: absolute;
    left: 260px;
    top: -20px;
  }
  .last {
    position: absolute;
    left: 269px;
    top: -15px;
  }
}

.newsletterSection {
  background: url(../../img/bg-newsletter.jpg) center center no-repeat;
  min-height: 182px;
  height: 100%;
  background-size: cover;
  padding: 20px 10% 20px 10%;
  color: white;
  .title {
    font-size: 25px;
    font-family: nowalt-regular, sans-serif;;
    text-transform: uppercase;
    margin: 20px auto;
    letter-spacing: 1.3px;
    b {
      font-family: 'now_altbold', sans-serif;
    }
  }
  input {
    width: 36%;
    border: 1px solid white;
    padding: 10px 20px;
    height: 55px;
    border-radius: 25px;
    color: $colorBlack;
    transition: 0.3s;

    &:focus {
      border: 2px solid $colorGreen;
      transition: 0.3s;
    }
  }

  input[type="checkbox"] {
    width: 27px;
    height: 16px;
    display: inline-block;
  }
  button {
    background: $colorGreen;
    border: 1px solid $colorGreen;
    font-family: 'now_altbold', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    margin-left: 40px;
    padding: 15px 30px 10px;
    transition: 0.3s;
    border-radius: 30px;
    position: relative;
    &:hover {
      background: white;
      color: $colorGreen;
      border: 1px solid $colorGreen;
      transition: 0.3s;
    }
  }
  form div {
    display: inline-block;
  }
}

.blogSection {
  margin: 40px auto;
  .header {
    font-family: 'now_altbold', sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    padding: 30px 15px;
  }
  .box {
    padding-right: 70px;
  }
  .img {
    img {
      width: 100%;
      min-height: 196px;
      max-height:196px;
      object-fit: cover;
    }
  }
  .date {
    border: 1px solid $colorGreen;
    padding: 5px;
    span {
      text-align: center;
      display: block;
      font-family: 'now_altbold', sans-serif;
      &:first-child {
        font-size: 33px;
        line-height: 30px;
        margin-top: 5px;
      }
      &:nth-child(2) {
        font-size: 8px;
      }
      &:nth-child(3) {
        font-size: 15px;
      }
    }
  }
  .date {
    min-width: 50px;
    font-family: $fontOpen;

  }
  .title {
    width: 72%;
    margin-left: 15px;
    float: right;
    a {
      color: $colorBlack;
      &:hover {
        color: #878787;
      }
    }
  }
  .date, .title {
    display: inline-block;
    margin-top: 20px;
  }
  .title {
    font-family: 'now_altbold', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .more {
    text-align: center;
    font-family: $fontOpen;
    font-weight: bold;
    font-size: 12px;
    a {
      color: #6a676a;
      transition: 0.3s;
      &:hover {
        padding-left: 10px;
        transition: 0.3s;
      }
    }
  }
}

footer {
  min-height: 260px;
  height: 100%;
  background-color: $colorGreen;
  color: $colorBlack;
  padding: 40px 0 15px;
  position: relative;
  float: left;
  margin: 0;
  width: 100%;
  a {
    color: $colorBlack;
    &:hover {
      color: $colorPurple;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      &:first-child {
        font-weight: bold;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
    }
  }

  .logo {
    img {
      max-width: 100%;
      filter: brightness(0%);
    }
  }
  .contact {
    font-size: 23px;
    font-family: now_altlight, sans-serif;
    text-transform: uppercase;
    b {
      font-family: 'now_altbold', sans-serif;
    }
    span {
      height: 70px;
      width: 70px;
      background-color: $colorPurple;
      border-radius: 50px;
      display: inline-block;
      float: left;
      margin-right: 25px;
    }
  }
  .copyrights {
    color: $colorBlack;
    font-size: 12px;
    font-weight: 200;
    border-top: 1px solid #2b2b2b;
    padding-top: 7px;
    margin: 20px 8% auto;
    .rights, .authors {
      width: 49.7%;
      display: inline-block;
      padding: 10px 3px;
    }
    .authors {
      text-align: right;
    }
    a {
      color: $colorPurple;
      font-weight: bold;
    }
  }
  .logo {
    width: 22%;
  }
  .list, .logo {
    display: inline-block;
    float: left;
  }
  .list {
    font-family: $fontOpen;
    width: 31%;
    margin-left: 30px;
  }
  .row {
    margin: auto 7%;
  }
}

.stayTunedSection {
  margin-top: 100px;
  color: $colorBlack;
  .img {
    img {
      max-width: 100%;
    }
  }
  .title {
    font-size: 36px;
    text-transform: uppercase;
    font-family: 'now_altbold', sans-serif;
  }
  .text {
    padding-top: 20px;
    font-family: $fontOpen;
    font-size: 14px;
    letter-spacing: 1px;
  }
  p {
    margin-top: 30px;
  }
  .check {
    margin: 50px auto;
    a {
      font-family: 'now_altbold', sans-serif;
      text-transform: uppercase;
      padding: 18px 27px 14px;
      border-radius: 27px;
      color: $colorPurple;
      font-size: 16px;
      background: transparent;
      transition: 0.3s;
      border: 1px solid $colorPurple;
      &:hover {
        background: $colorPurple;
        color: white;
        transition: 0.3s;
      }
    }
  }
}

.cookiepolicy {
  background: #fff;
  bottom: 0;
  font: 14px Arial, Helvetica, sans-serif;
  left: 0;
  padding: 15px 50px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 99990;
  width: 100%;
  line-height: 24px;

  a {
    color: $colorGreen;
  }
  .button {
    background-color: $colorGreen;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
    padding: 5px 10px;
    &:hover {
      text-decoration: none;
    }
  }
}
