$colorGreen: #c8d700;
$colorPurple: #a20290;
$colorBlack: #2b2b2b;
$fontOpen: 'Open Sans', sans-serif;

body {
  font-family: nowalt-regular, sans-serif;
  background-color: #fdf9f0;
}

a {
  &:focus, &:hover {
    text-decoration: none;
  }
}

@font-face {
  font-family: 'now_altbold';
  src: url('../../fonts/nowalt-bold.otf') format('Opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nowalt-regular';
  src: url('../../fonts/nowalt-regular.otf') format('Opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'now_altlight';
  src: url('../../fonts/nowalt-light.otf') format('Opentype');
  font-weight: normal;
  font-style: normal;
}

.hvr {
  transform: scale(1.0);
  transition: 0.5s;
  &:hover {
    transform: scale(1.1);
    transition: 0.5s;
  }
}

*:focus {
  outline: none;
  box-shadow: none;
}

a:focus {
  outline: none;
}

@import 'index';

@import 'nav';

@import 'about';

@import 'contact';

@import 'offer';

@import 'blog';

@import 'job';

@import 'fe';

@import 'rwd';

.justify-box {
  text-align: justify;
  text-indent: 20px;
}