@media all and (max-width: 767px) {
  .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    color: #555;
    background-color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: $colorPurple;
  }
  .navbar-default .navbar-nav > li.dropdown > a:focus, .navbar-default .navbar-nav > li.dropdown > a:hover {
    color: $colorBlack;
  }
  .navbar-fixed div#navbar {
    margin-top: 20px;
  }
  ul.nav.navbar-nav.navbar-right {
    background-color: $colorGreen;
    z-index: 9999;
    position: relative;
  }
  .navbar-default.navbar-fixed {
    height: 76px;
    margin-top: 0;
  }
  .navbar-fixed .logo {
    margin-left: 10px;
  }
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    margin-top: 28px;
  }
  .newsletterSection {
    padding: 20px 4%;
  }
  .newsletterSection input {
    width: 100%;
  }
  .newsletterSection button {
    margin-left: 0;
    margin-top: 13px;
    margin-bottom: 17px;
  }
  .aboutSection .counters .experience {
    top: -70px;
    right: 249px;

  }
  .aboutSection .counters .experience b {
    top: 28px;
    right: -189px;
    font-size: 26px;
  }
  .blogSection {
    .box {
      padding: 0;
    }
  }

  footer .logo {
    width: 100%;
  }
  footer .list, footer .logo {
    display: block;
    float: none;
  }
  footer .list {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 4px;
  }
  footer .row {
    margin: auto 2%;
  }
  footer .copyrights {
    margin: 20px 0;
  }
  .aboutPage .box figure {
    float: none;
    max-width: 300px;
    margin: auto;
  }
  .aboutPageTop h1 {
    left: 5%;
  }
  .offerPage .rocket {
    display: none;
  }
  .offerPage .whySection .col-sm-4 {
    margin-bottom: 30px;
  }
  .offerPage .text {
    margin: -10px 9%;
    max-width: 650px;
    padding-top: 38px;
    padding-bottom: 20px;
  }
  .whySection {
    padding-bottom: 40px;
  }
  .offerPage .whySection .title {
    margin: 10px 10%;
  }
  .offerPage .a, .offerPage .b {
    width: 49.99999%;
    min-height: 497px;
    margin-left: -1px;
  }
  .a.full-img, .b.full-img {
    height: 470px;
  }
  footer .copyrights .rights, footer .copyrights .authors {
    width: 47.7%;
  }

}

@media (min-width: 1400px) {
  .blogSection .container {
    width: 1400px;
  }
  .activitiesSection .container {
    width: 1400px;
  }
  .blogPage .blogSection .container.singleNews {
    width: 1170px;
  }
  .activitiesSection ul {
    height: 200px;
  }
  .blogPage .blogSection .title {
    width: 75%;
  }
  .blogSection .title {
    width: 80%;
  }
  .blogPage .singleNews .title {
    width: 90%;
  }
}

@media all and (min-width: 767px) and (max-width: 992px) {
  .aboutSection .counters .partner {
    position: absolute;
    top: 135px;
    right: 0;
  }
  .aboutSection .counters .img {
    position: relative;
    left: -74px;
  }
  .aboutSection .counters .department {
    right: 0;
    top: 46px;
  }
  .aboutSection .counters .experience {
    font-size: 23px;
    top: -70px;
    right: 160px;
  }
  .aboutSection .counters .experience b {
    right: -223px;
    font-size: 30px;
  }
  footer .list {
    width: 32%;
    margin-left: 30px;
  }
  footer .contact {
    margin: 11px auto;
  }
}

@media all and (min-width: 768px) and (max-width: 841px) {
  ul.nav.navbar-nav.navbar-right {
    padding: 0;
    margin-top: 30px;
  }

  .topSection .logo {
    width: 166px;
    top: 50px;
    img {
      width: 90%;
    }
  }
  .navbar-fixed .logo {
    top: 2px;
  }
}

@media all and (max-width: 850px) {
  .contactPage .a, .contactPage .b {
    width: 100%;
    display: block;
    float: none;
  }
  .offerPage .a, .offerPage .b {
    width: 100%;
    display: block;
    float: none;
  }
  .contactPage .a {
    margin-bottom: 80px;
  }
  .contactPage .locations {
    margin: 50px 4%;
  }
}

@media all and (max-width: 1309px) {

  .jobPage .object {
    display: none;
  }
  .jobPage .requirements ul {
    margin: auto;
    padding: 40px 10%;
    width: 100%;
  }
}

@media all and (min-width: 1310px) and (max-width: 1846px) {
  .jobPage .box {
    margin: 50px auto 40px;
  }
  .jobPage .object {
    right: 2%;
  }
}

@media all and (max-width: 992px) {

  .activitiesSection .titleBox {
    width: 100%;
    text-align: center;
  }
  .activitiesSection .second {
    position: absolute;
    left: 65%;
    top: -16px;
  }
  .activitiesSection .last {
    position: absolute;
    left: 49%;
    top: -15px;
    margin: auto;
    right: 0;
  }
  .activitiesSection .header {
    margin-left: 5%;
  }
  .activitiesSection .title {
    margin-right: 13%;
  }
  .activitiesSection .title.first {
    margin-right: 0;
    //  width: 219px;
  }
  .blogSection .title {
    width: 100%;
    margin-left: 0;
  }
  .fePage .quote .author {
    margin-top: 20px;
  }
}

@media all and (min-width: 767px) {
  .navbar-default.navbar-fixed {
    height: 77px;
  }
}

@media all and (max-width: 500px) {
  .activitiesSection .title.first {
    width: 100%;
  }
  .topSection .actions a {
    display: block;
    text-align: center;
    margin: auto 10% 20px;
  }
  .topSection .actions a:first-child {
    margin-right: 10%;
  }
  .topSection .view {
    position: absolute;
    top: 300px;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    margin-top: 48px;
  }
  .topSection .top-nav ul {
    text-align: center;
  }
  .opinionsSection ul {
    top: 130px;
  }
  .aboutSection .counters .partner {
    position: absolute;
    top: 135px;
    right: 0;
  }
  .aboutSection .counters .img {
    position: relative;
    left: -74px;
  }
  .aboutSection .counters .department {
    right: 0;
    top: 30px;
  }
  .aboutSection .counters .experience {
    right: 0;
    left: 0;
  }
  .aboutSection .counters .experience b {
    top: 31px;
    right: -162px;
    font-size: 23px;
  }
  .activitiesSection .last, .activitiesSection .second, .activitiesSection .hours {
    position: relative;
    text-align: center;
    right: 0;
    top: 20px;
    margin: auto;
    left: 0;
    display: block;
  }
  .activitiesSection .titleBox {
    width: 100%;
    text-align: left;
  }
  .activitiesSection .title {
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
  }
  .blogSection .title {
    width: 70%;
  }
  .topSection .top-nav ul {
    padding: 0;
  }
  .contactPage .form {
    width: auto;
    margin: auto 5%;
  }
  .aboutPageTop h1 {
    font-size: 30px;
    font-family: now_altlight, sans-serif;
  }
  footer .copyrights .rights, footer .copyrights .authors {
    width: 100%;
    display: block;
    text-align: center;
  }
  .contactPage .gmaps #map {
    height: 400px;
    width: 100%;
  }
  .contactPage .input {
    width: calc(100% - 0em);
  }
  .jobPage .box {
    width: 100%;
    padding-top: 7px;
  }
  .offerPage .a, .offerPage .b {
    height: 100%;
  }
  .offerPage .a.full-img, .offerPage .b.full-img {
    height: 470px;
  }
  .whySection .description {
    margin: auto 1%;
  }
  .aboutPage .team .box img {
    height: 200px;
  }
  .aboutPage .team .box {
    height: 200px;
  }
  .aboutPage figure.effect-goliath h2, .aboutPage figure.effect-goliath p {
    bottom: 19px;
    padding: 12px;
  }
  .singleNews .title {
    margin-left: 10px;
  }
  .fePage .quote .quoteArea {
    background: none;
    width: 100%;
    height: 100%;
    margin: 0 auto 40px;
    border: 2px solid $colorPurple;
    border-radius: 50px;
  }
  .singleNews .title {
    width: 69%;
  }
}

@media all and (max-width: 430px) {
  .aboutSection .counters .department {
    top: 90px;
  }
  .aboutSection .counters .partner {
    top: 189px;
    text-align: center;
    line-height: 25px;
  }
  .aboutSection .counters .experience b {
    top: 31px;
    right: -157px;
    font-size: 21px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .activitiesSection .title {
    text-align: left;
  }
  .activitiesSection .hours {
    left: -65px;
    transform: scale(0.8);
  }
  .activitiesSection .second {
    position: absolute;
    left: 200px;
    top: -20px;
  }
  .activitiesSection .last {
    position: absolute;
    left: 219px;
    top: -15px;
  }
  .blogSection .title {
    width: 70%;
    margin-left: 0;
  }

  .fePage .quote .quoteArea {
    background: none;
    width: 100%;
    height: 100%;
    margin: 0 auto 40px;
    border: 2px solid $colorPurple;
    border-radius: 50px;
  }

}

@media all and (max-width: 1450px) {
  .offerPage .whySection .title {
    height: 57px;
    margin: 10px auto !important;
  }
}

@media all and (max-width: 1400px) {
  .activitiesSection .title {
    font-size: 23px;
  }
}

@media all and (max-width: 1200px) {
  .activitiesSection .title {
    font-size: 19px;
  }

  .activitiesSection ul {
    min-height: 310px;
  }
}

@media all and (max-width: 991px) {
  .activitiesSection ul {
    min-height: auto;
  }
}

@media all and (max-width: 900px) {
  .offerPage .whySection .title {
    font-size: 17px;
  }
}

@media all and (max-width: 767px) {
  .offerPage .whySection .title {
    height: auto;
  }
}