.contactPageTop {
  background: url(../../img/bg-contact.jpg) center center no-repeat;
}

.contactPage {
  .locations {
    margin: 50px 0 50px 15%;
    text-transform: uppercase;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        font-family: 'now_altbold', sans-serif;
        font-size: 22px;
        color: #2b2b2b;
        cursor: pointer;
        span {
          color: #2b2b2b;
        }
        &:hover, &:focus {
          color: #d4d2ce;
          span {
            color: #2b2b2b;
          }
        }
      }
    }
    .active {
      color: #d4d2ce;
    }
    span {
      font-weight: bold;
      position: relative;
      top: -4px;
      margin: auto 10px;
    }
  }
  h2 {
    color: #2b2b2b;
    font-family: 'now_altbold', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
  }
  .input {
    position: relative;
    z-index: 1;
    max-width: 100%;
    width: 100%;
    vertical-align: top;
    font-size: 18px;
    font-family: $fontOpen;
    display: block;
  }

  .input__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 60%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    color: #aaa;
    font-weight: bold;
    -webkit-appearance: none; /* for box shadows to show on iOS */
  }

  .input__field:focus {
    outline: none;
  }

  .input__label {
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 40%;
    color: #6a7989;
    font-weight: bold;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .input__label-content {
    position: relative;
    display: block;
    padding: 1.6em 0;
    width: 100%;
  }

  .graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
  }

  .icon {
    color: #ddd;
    font-size: 150%;
  }

  .input__field--isao {
    z-index: 10;
    padding: 0.75em 0.1em 0.25em;
    width: 100%;
    max-width: 100%;
    background: transparent;
    color: #afb3b8;
  }

  .input__label--isao {
    position: relative;
    overflow: hidden;
    padding: 0;
    width: 100%;
    max-width: 100%;
    color: #545454;
    text-align: left;
  }

  .input__label--isao::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 7px;
    background: #5a5a5a;
    transform: scale3d(1, 0.4, 1);
    transform-origin: 50% 100%;
    transition: transform 0.3s, background-color 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  .input__label--isao::after {
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.75em 0.15em;
    color: $colorGreen;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition: opacity 0.3s, transform 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    pointer-events: none;
  }

  .textarea {
    min-height: 30px;
    min-width: 100%;
  }

  .input__field--isao:focus + .input__label--isao::before {
    background-color: $colorGreen;
    transform: scale3d(1, 1, 1);
  }

  .input__field--isao:focus + .input__label--isao {
    pointer-events: none;
  }

  .input__field--isao:focus + .input__label--isao::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .input__label-content--isao {
    padding: 0.75em 0.15em;
    transition: opacity 0.3s, transform 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  .input__field--isao:focus + .input__label--isao .input__label-content--isao {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  .alert-success {
    color: white;
    background-color: #c8d700;
    border-color: #c8d700;
  }
  .alert {
    margin-top: 28px;
  }
  button {
    font-family: 'now_altbold', sans-serif;
    text-transform: uppercase;
    padding: 17px 40px 12px;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    background: $colorGreen;
    border: 1px solid transparent;
    margin: 20px auto;
    position: relative;
    transition: 0.3s;
    &:hover {
      color: $colorGreen;
      background: #FDF9F0;
      border: 1px solid $colorGreen;
      transition: 0.3s;
    }
  }
  .address {
    background: url(../../img/bg-address.jpg) center center;
    color: white;
    height: 546px;
    .view {
      display: none;
      position: relative;
      top: 118px;
      left: 8%;
      width: 310px;
      ul {
        &:first-child {
          text-transform: uppercase;
        }
        padding: 0;
        list-style: none;
        margin-bottom: 35px;
        .pointer {
          margin-bottom: 20px;
        }
        li {
          font-family: now_altlight, sans-serif;
          font-size: 21px;
          margin-bottom: 5px;
          b {
            font-family: 'now_altbold', sans-serif;
          }
        }
      }
      &.active {
        display: block;
      }
    }
  }
  #maps {
    z-index: -1;
    position: relative;
  }
  .gmaps {
    width: 100%;
    position: relative;
    display: none;
    &.current {
      display: block;
    }
    #map1, #map2, #map3, #map4 {
      height: 500px;
      width: 100%;
    }
  }
  .form {
    width: 100%;
    position: relative;
    margin: 45px auto 40px;
    padding: 0 8%;
  }
  a {
    color: white;
  }
  .a, .b {
    width: 49.99999%;
    display: inline-block;
    float: left;
  }
  .mainView {
    width: 100%;
    min-height: 546px;
  }
  .title {
    font-size: 28px;
    font-family: 'now_altbold', sans-serif;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
  }
}

.control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 200px;
  height: 210px;
  margin: 10px;
}

.control {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  span {
    display: block;
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.control__indicator {
  position: absolute;
  top: 1px;
  left: 4px;
  height: 15px;
  width: 15px;
  background: #fff;
  border: 1px solid $colorGreen;
  border-radius: 3px;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.control {
  &:hover input ~ .control__indicator {
    background: #fff;
  }
  input {
    border-radius: 3px;
    &:focus ~ .control__indicator {
      background: #fff;
    }
    &:checked ~ .control__indicator {
      background: $colorGreen;
      border: 1px solid $colorGreen;
    }
  }
  &:hover input:not([disabled]):checked ~ .control__indicator {
    background: $colorGreen;
  }
  input {
    &:checked:focus ~ .control__indicator {
      background: $colorGreen;
    }
    &:disabled ~ .control__indicator {
      background: $colorGreen;
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control__indicator:after {
  display: block;
}

.control--checkbox {
  .control__indicator:after {
    top: 1px;
    left: 1px;
    width: 11px;
    height: 11px;
    background: url(../../img/icon-check-white.png);
  }
  input:disabled ~ .control__indicator:after {
    border-color: #fff;
  }

  &.faded {
    opacity: 0.3;
    pointer-events: none;
  }

}

.control--radio {
  &.faded {
    opacity: 0.3;
    pointer-events: none;
  }

  input[type='radio'] {
    border-radius: 50%;
  }
  .control__indicator:after {
    top: 8px;
    left: 4px;
    width: 19px;
    height: 14px;
  }
  input:disabled ~ .control__indicator:after {
    background: #fff;
  }
}