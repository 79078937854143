.fePageTop {
  background: url(../../img/bg-fe.jpg) center center no-repeat;
  background-size: cover;
}

.fePage {
  margin: 50px auto;
  h2, h4, h5 {
    color: $colorBlack;
    font-family: 'now_altbold', sans-serif;
    font-size: 30px;
    text-transform: uppercase;
  }
  h3 {
    font-family: $fontOpen;
    font-size: 20px;
    color: $colorBlack;
  }
  .fe {
    margin: 30px auto 40px;
  }
  .actions {
    a {
      display: inline-block;
      font-family: 'now_altbold', sans-serif;
      text-transform: uppercase;
      padding: 18px 27px 14px;
      border-radius: 27px;
      color: white;
      font-size: 16px;
      background: $colorGreen;
      transition: 0.3s;
      border: 1px solid $colorGreen;
      margin-right: 20px;
      margin-bottom: 13px;
      &:hover {
        color: $colorGreen;
        background: white;
        border: 1px solid $colorGreen;
        transition: 0.3s;
      }

    }
  }
  .watch {
    a {
      margin: 80px auto 40px;
      text-align: center;
      display: block;
      color: $colorBlack;
      font-family: 'now_altbold', sans-serif;
      font-size: 45px;
      text-transform: uppercase;
      transition: 0.4s;
      background: #fff;
      padding: 50px;
      span {
        position: relative;
        top: 5px;
        left: 7px;
      }
      img {
        transition: 0.3s;
      }
      &:hover {
        span {
          color: #5f5f5f;
        }
        img {
          transition: 0.3s;
          transform: rotate(360deg);
        }
      }
    }
  }
  .img, .fe {
    img {
      pointer-events: none;
      max-width: 100%;
    }
  }
  .aboutMethod {
    margin-top: 50px;
    .actions {
      margin-top: 10px;
      padding: 12px 22px 11px;
      text-align: center;
      a {
        color: white;
        &:hover {
          color:$colorGreen;
        }
      }
    }
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
  }
  h5 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .text {
    padding: 0 3%;
  }

  ul {
    text-align: left;
    padding: 0;
    list-style: none url(../../img/icon-ok-small.png);

    li {
      font-family: $fontOpen;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      span {
        position: relative;
        top: -3px;
        left: 3px;
      }
    }
  }

  .moreFacts {
    position: relative;
    padding: 70px 20%;
    margin: 80px auto;
    color: white;
    background: url(../../img/bg-fe-purple.jpg) center center;
    background-size: cover;
    ul {
      li {
        font-family: 'now_altbold', sans-serif;
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }
  .quote {
    .title, .author span {
      font-family: 'now_altbold', sans-serif;
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .content, .about {
      font-family: $fontOpen;
      font-size: 14px;
      color: $colorBlack;
    }
    .quoteArea {
      background: url(../../img/bg-quote.png) no-repeat;
      height: 447px;
      width: 455px;
      padding: 40px 50px 30px 35px;
      margin: auto;
      .content {
        text-align: justify;
      }
      .title {
        text-align: justify;
      }
    }
    .about {
      margin-top: 15px;
      font-size: 12px;
    }
    .author span {
      position: relative;
      top: 35px;
      left: -4px;
    }
  }

  .authoritiesSection {
    margin-top: 40px;
    text-align: justify;
    .title {
      font-family: 'now_altbold', sans-serif;
      font-size: 21px;
      text-transform: uppercase;
      margin: 40px auto 20px;
      &.last {
        text-align: right;
      }
    }
    .description {
      font-family: $fontOpen;
      font-size: 14px;
    }
  }

  iframe {
    margin:50px auto;
    text-align: center;
  }

}