.offerPageTop {
  background: url(../../img/bg-offer.jpg) center center no-repeat;
  background-size: cover;
}

.offerTeenPageTop {
  background: url(../../img/bg-offer-teenagers.jpg) center center no-repeat;
  background-size: cover;
}

.offerHsgPageTop {
  background: url(../../img/bg-offer-adults.jpg) center center no-repeat;
  background-size: cover;
}

.offerPage {
  margin: 50px auto 0;
  font-family: $fontOpen;
  h2, h3, h4 {
    font-family: 'now_altbold', sans-serif;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .rocket {
    position: absolute;
    top: -150px;
    img {
      width: 100%;
    }
  }
  p {
    margin-top: 15px;
  }

  .whySection .title {
    margin: 10px 10px;
  }

  .whySection .img {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .a, .b {
    width: 49.99999%;
    display: inline-block;
    float: left;
    height: 497px;
    margin-left: -1px;
  }
  .a, .b {
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .text {
    margin: 7% 9%;
    max-width: 650px;

    .more {
      font-family: 'now_altbold', sans-serif;
      text-transform: uppercase;
      padding: 0;
      border-radius: 30px;
      color: white;
      font-size: 16px;
      position: relative;
      top: 10px;
      transition: 0.3s;
      &:hover {
        color: $colorGreen;
      }
    }
  }
  .organization {
    background: $colorPurple;
    color: white;
    a {
      color: white;
      &:hover {
        color: $colorGreen;
      }
    }
  }
  h4 {
    color: white;
  }
  ul {
    padding: 0;
    list-style: none url(../../img/icon-ok-small.png);
    list-style-position: inside;
    li {
      margin-bottom: 6px;
      span {
        position: relative;
        top: -3px;
        left: 3px;

      }
    }
  }
  .mainView {
    border-bottom: 1px solid #cecece;
    padding-bottom: 70px;
    margin-bottom: 100px;
  }
  .levels {
    margin-top: 10px;
    list-style: none;
  }
  .col-sm-6 {
    margin-bottom: 30px;
  }
  .adults {
    list-style-position: outside;
    text-align: justify;
    span {
      position: relative;
      top: -5px;
    }
  }
}