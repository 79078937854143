@charset "UTF-8";
body {
  font-family: nowalt-regular, sans-serif;
  background-color: #fdf9f0;
}

a:focus, a:hover {
  text-decoration: none;
}

@font-face {
  font-family: 'now_altbold';
  src: url("../../fonts/nowalt-bold.otf") format("Opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nowalt-regular';
  src: url("../../fonts/nowalt-regular.otf") format("Opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'now_altlight';
  src: url("../../fonts/nowalt-light.otf") format("Opentype");
  font-weight: normal;
  font-style: normal;
}

.hvr {
  transform: scale(1);
  transition: 0.5s;
}

.hvr:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

*:focus {
  outline: none;
  box-shadow: none;
}

a:focus {
  outline: none;
}

.topSection {
  background: url(../../img/bg-main-top.jpg) center center no-repeat;
  background-size: cover;
  height: 900px;
}

.topSection .nav {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 30px;
}

.topSection .logo {
  position: relative;
  top: 11px;
  width: 200px;
  z-index: 9999;
}

.topSection .navbar-fixed .logo img {
  width: 73%;
}

.topSection .view {
  position: absolute;
  top: 50%;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
}

.topSection h1 {
  text-transform: uppercase;
  font-size: 35px;
  font-family: now_altlight, sans-serif;
  padding: 0;
  margin: 0;
  color: white;
}

.topSection h1 b {
  display: block;
  margin-top: 5px;
  font-family: 'now_altbold', sans-serif;
}

.topSection .actions {
  font-family: now_altlight, sans-serif;
  text-align: center;
  position: relative;
  top: 50px;
}

.topSection .actions a {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 23px 35px 19px;
  border-radius: 30px;
  color: white;
  font-size: 16px;
}

.topSection .actions a:hover {
  text-decoration: none;
}

.topSection .actions a:first-child {
  transition: 0.5s;
  background-color: #c8d700;
  margin-right: 30px;
}

.topSection .actions a:first-child:hover {
  background-color: white;
  color: #c8d700;
  transition: 0.5s;
}

.topSection .actions a:last-child {
  background-color: transparent;
  border: 1px solid white;
  transition: 0.5s;
}

.topSection .actions a:last-child:hover {
  background-color: #c8d700;
  transition: 0.5s;
  border: 1px solid transparent;
}

.topSection .top-nav {
  position: absolute;
  right: 3%;
  top: 30px;
  z-index: 999999;
}

.topSection .top-nav ul {
  list-style: none;
  margin: 0;
}

.topSection .top-nav ul li {
  margin-bottom: 20px;
  display: inline-block;
}

.topSection .top-nav ul li a {
  text-transform: uppercase;
  padding: 8px 20px 6px;
  border-radius: 20px;
  color: white;
}

@media all and (max-width: 700px) {
  .topSection .top-nav ul li a {
    padding: 8px 14px 6px;
  }
}

.topSection .top-nav ul li a:hover {
  text-decoration: none;
}

.topSection .top-nav ul li:first-child a {
  background-color: #a20290;
  margin-right: 10px;
  transition: 0.5s;
}

.topSection .top-nav ul li:first-child a:hover {
  background-color: white;
  color: #a20290;
  transition: 0.7s;
}

.topSection .top-nav ul li a {
  background-color: #c8d700;
  transition: 0.7s;
}

.topSection .top-nav ul li a:hover {
  background-color: white;
  color: #c8d700;
  transition: 0.7s;
}

.topSection .overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(22, 24, 22, 0.21), rgba(148, 154, 151, 0)) center;
}

.aboutSection {
  border-bottom: 1px solid #cecece;
  margin: 70px auto;
  padding-bottom: 70px;
  font-family: "Open Sans", sans-serif;
}

.aboutSection .title {
  color: #2b2b2b;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 12px;
}

.aboutSection .description {
  font-size: 15px;
}

.aboutSection .description .team {
  margin-top: 20px;
  margin-right: 20px;
  display: inline-block;
}

.aboutSection .description .team a {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 18px 27px 14px;
  border-radius: 27px;
  color: white;
  font-size: 16px;
  background: #c8d700;
  transition: 0.3s;
  border: 1px solid #c8d700;
}

.aboutSection .description .team a:hover {
  color: #c8d700;
  background: white;
  border: 1px solid #c8d700;
  transition: 0.3s;
}

.aboutSection .description .fb {
  margin-top: 20px;
  display: inline-block;
}

.aboutSection .logo {
  margin-top: 20px;
  margin-bottom: 34px;
}

.aboutSection .counters {
  top: 50px;
  position: relative;
}

.aboutSection .counters .experience {
  position: absolute;
  font-size: 25px;
  font-family: 'nowalt-regular', sans-serif;
  top: -70px;
  right: 210px;
}

.aboutSection .counters .experience b {
  font-family: 'now_altbold', sans-serif;
  font-weight: 800;
  position: absolute;
  top: 30px;
  right: -268px;
  font-size: 35px;
  text-transform: uppercase;
}

.aboutSection .counters .experience span {
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  color: #a30691;
  font-size: 77px;
  position: relative;
}

.aboutSection .counters .experience span:nth-child(2) {
  font-size: 25px;
  font-family: now_altlight, sans-serif;
  position: relative;
  top: -32px;
  color: #2b2b2b;
  font-weight: 200;
}

.aboutSection .counters .department {
  font-family: 'now_altbold', sans-serif;
  font-size: 25px;
  position: absolute;
  top: 12px;
  right: 60px;
  text-transform: uppercase;
}

.aboutSection .counters .department span {
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  color: #a30691;
  font-size: 77px;
}

.aboutSection .counters .partner {
  position: absolute;
  top: 135px;
  right: 110px;
  font-family: 'now_altbold', sans-serif;
  font-weight: 800;
  font-size: 25px;
  text-transform: uppercase;
}

.aboutSection .counters .partner span {
  text-transform: none;
  display: block;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  color: #a30691;
  text-align: right;
}

.aboutSection .col-sm-6 {
  margin-bottom: 30px;
}

.whySection {
  padding-bottom: 80px;
  text-align: center;
}

.whySection .title {
  text-transform: uppercase;
  color: #2b2b2b;
  font-size: 20px;
  margin: 10px auto;
  font-family: 'now_altbold', sans-serif;
}

.whySection .description {
  margin: auto 20%;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.opinionsSection {
  background: fixed url(../../img/bg-opinions.jpg) center center no-repeat;
  background-size: cover;
  min-height: 387px;
  overflow: hidden;
}

.opinionsSection .title {
  font-family: 'now_altbold', sans-serif;
  position: relative;
  top: 100px;
  font-size: 30px;
  color: white;
  text-align: center;
  text-transform: uppercase;
}

.opinionsSection ul {
  list-style: none;
  color: white;
  margin: 0;
  text-align: center;
  position: relative;
  top: 160px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  padding: 0 5%;
}

.opinionsSection ul li {
  margin-bottom: 15px;
}

.opinionsSection ul li span {
  position: relative;
  top: -4px;
  left: 10px;
}

.opinionsSection ul li span:before {
  content: '“';
}

.opinionsSection ul li span:after {
  content: '“';
}

.opinionsSection ul li .author {
  font-weight: bold;
  margin-top: 5px;
}

.opinionsSection .slick-vertical .slick-slide {
  height: 400px;
}

@media all and (max-width: 500px) {
  .opinionsSection .slick-vertical .slick-slide {
    font-size: 14px;
  }
}

.activitiesSection {
  margin: 40px auto;
}

.activitiesSection .titleBox {
  width: 100%;
  height: 66px;
  text-align: center;
}

.activitiesSection .header {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  color: #2b2b2b;
  text-align: center;
  margin-bottom: 40px;
}

.activitiesSection .title {
  font-family: now_altlight, sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 25px;
  color: #2b2b2b;
  text-align: center;
}

.activitiesSection .title b {
  font-family: 'now_altbold', sans-serif;
}

.activitiesSection .hours {
  display: inline-block;
  border: 2px solid #a20290;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  position: relative;
  left: 0;
  top: -30px;
  font-family: 'now_altbold', sans-serif;
}

.activitiesSection .hours span {
  color: #a20290;
  position: relative;
  top: 28px;
  font-size: 25px;
  left: 0;
  font-weight: bold;
  line-height: 22px;
}

.activitiesSection ul {
  padding-left: 17px;
  margin: 40px 5% 20px 0;
  line-height: 22px;
  list-style: none url(../../img/icon-ok-purple.png);
}

.activitiesSection ul li {
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.activitiesSection .checkout {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.activitiesSection .checkout a {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 18px 27px 14px;
  border-radius: 27px;
  color: white;
  font-size: 16px;
  background-color: #c8d700;
}

.activitiesSection .second {
  position: absolute;
  left: 260px;
  top: -20px;
}

.activitiesSection .last {
  position: absolute;
  left: 269px;
  top: -15px;
}

.newsletterSection {
  background: url(../../img/bg-newsletter.jpg) center center no-repeat;
  min-height: 182px;
  height: 100%;
  background-size: cover;
  padding: 20px 10% 20px 10%;
  color: white;
}

.newsletterSection .title {
  font-size: 25px;
  font-family: nowalt-regular, sans-serif;
  text-transform: uppercase;
  margin: 20px auto;
  letter-spacing: 1.3px;
}

.newsletterSection .title b {
  font-family: 'now_altbold', sans-serif;
}

.newsletterSection input {
  width: 36%;
  border: 1px solid white;
  padding: 10px 20px;
  height: 55px;
  border-radius: 25px;
  color: #2b2b2b;
  transition: 0.3s;
}

.newsletterSection input:focus {
  border: 2px solid #c8d700;
  transition: 0.3s;
}

.newsletterSection input[type="checkbox"] {
  width: 27px;
  height: 16px;
  display: inline-block;
}

.newsletterSection button {
  background: #c8d700;
  border: 1px solid #c8d700;
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 40px;
  padding: 15px 30px 10px;
  transition: 0.3s;
  border-radius: 30px;
  position: relative;
}

.newsletterSection button:hover {
  background: white;
  color: #c8d700;
  border: 1px solid #c8d700;
  transition: 0.3s;
}

.newsletterSection form div {
  display: inline-block;
}

.blogSection {
  margin: 40px auto;
}

.blogSection .header {
  font-family: 'now_altbold', sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  padding: 30px 15px;
}

.blogSection .box {
  padding-right: 70px;
}

.blogSection .img img {
  width: 100%;
  min-height: 196px;
  max-height: 196px;
  object-fit: cover;
}

.blogSection .date {
  border: 1px solid #c8d700;
  padding: 5px;
}

.blogSection .date span {
  text-align: center;
  display: block;
  font-family: 'now_altbold', sans-serif;
}

.blogSection .date span:first-child {
  font-size: 33px;
  line-height: 30px;
  margin-top: 5px;
}

.blogSection .date span:nth-child(2) {
  font-size: 8px;
}

.blogSection .date span:nth-child(3) {
  font-size: 15px;
}

.blogSection .date {
  min-width: 50px;
  font-family: "Open Sans", sans-serif;
}

.blogSection .title {
  width: 72%;
  margin-left: 15px;
  float: right;
}

.blogSection .title a {
  color: #2b2b2b;
}

.blogSection .title a:hover {
  color: #878787;
}

.blogSection .date, .blogSection .title {
  display: inline-block;
  margin-top: 20px;
}

.blogSection .title {
  font-family: 'now_altbold', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.blogSection .more {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 12px;
}

.blogSection .more a {
  color: #6a676a;
  transition: 0.3s;
}

.blogSection .more a:hover {
  padding-left: 10px;
  transition: 0.3s;
}

footer {
  min-height: 260px;
  height: 100%;
  background-color: #c8d700;
  color: #2b2b2b;
  padding: 40px 0 15px;
  position: relative;
  float: left;
  margin: 0;
  width: 100%;
}

footer a {
  color: #2b2b2b;
}

footer a:hover {
  color: #a20290;
}

footer ul {
  padding: 0;
  list-style: none;
}

footer ul li:first-child {
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

footer .logo img {
  max-width: 100%;
  filter: brightness(0%);
}

footer .contact {
  font-size: 23px;
  font-family: now_altlight, sans-serif;
  text-transform: uppercase;
}

footer .contact b {
  font-family: 'now_altbold', sans-serif;
}

footer .contact span {
  height: 70px;
  width: 70px;
  background-color: #a20290;
  border-radius: 50px;
  display: inline-block;
  float: left;
  margin-right: 25px;
}

footer .copyrights {
  color: #2b2b2b;
  font-size: 12px;
  font-weight: 200;
  border-top: 1px solid #2b2b2b;
  padding-top: 7px;
  margin: 20px 8% auto;
}

footer .copyrights .rights, footer .copyrights .authors {
  width: 49.7%;
  display: inline-block;
  padding: 10px 3px;
}

footer .copyrights .authors {
  text-align: right;
}

footer .copyrights a {
  color: #a20290;
  font-weight: bold;
}

footer .logo {
  width: 22%;
}

footer .list, footer .logo {
  display: inline-block;
  float: left;
}

footer .list {
  font-family: "Open Sans", sans-serif;
  width: 31%;
  margin-left: 30px;
}

footer .row {
  margin: auto 7%;
}

.stayTunedSection {
  margin-top: 100px;
  color: #2b2b2b;
}

.stayTunedSection .img img {
  max-width: 100%;
}

.stayTunedSection .title {
  font-size: 36px;
  text-transform: uppercase;
  font-family: 'now_altbold', sans-serif;
}

.stayTunedSection .text {
  padding-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
}

.stayTunedSection p {
  margin-top: 30px;
}

.stayTunedSection .check {
  margin: 50px auto;
}

.stayTunedSection .check a {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 18px 27px 14px;
  border-radius: 27px;
  color: #a20290;
  font-size: 16px;
  background: transparent;
  transition: 0.3s;
  border: 1px solid #a20290;
}

.stayTunedSection .check a:hover {
  background: #a20290;
  color: white;
  transition: 0.3s;
}

.cookiepolicy {
  background: #fff;
  bottom: 0;
  font: 14px Arial, Helvetica, sans-serif;
  left: 0;
  padding: 15px 50px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 99990;
  width: 100%;
  line-height: 24px;
}

.cookiepolicy a {
  color: #c8d700;
}

.cookiepolicy .button {
  background-color: #c8d700;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 10px;
}

.cookiepolicy .button:hover {
  text-decoration: none;
}

.navbar-default .navbar-nav > li > a {
  font-size: 13px;
  color: white;
  font-weight: 200;
}

.navbar-default button {
  background-color: transparent;
  border-color: transparent;
  position: relative;
  top: 20px;
}

.navbar-default .navbar-toggle {
  border-color: white;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #d9d9d9;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a {
  transition: 0.6s;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: #c8d700;
  transition: 0.6s;
}

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.navbar-default .navbar-nav > li.dropdown > a:focus, .navbar-default .navbar-nav > li.dropdown > a:hover {
  color: white;
}

.navbar-right .dropdown-menu {
  right: -1px;
  left: auto;
  top: 48px;
}

.dropdown-menu > li > a {
  padding: 12px 18px;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

.navbar-toggle {
  margin-right: 5px;
}

#navigation {
  margin: auto 19%;
}

.navbar-default .navbar-nav > li > a {
  font-size: 13px;
  color: white;
  font-weight: 200;
}

.navbar-default {
  background-color: transparent;
  border-color: transparent;
  z-index: 99999;
}

.navbar-default .navbar-toggle {
  border-color: #d6d8d6;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #d9d9d9;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a {
  transition: 0.6s;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color: #a20290;
  transition: 0.6s;
}

.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.dropdown-menu > li > a {
  color: white;
}

.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.navbar-default .navbar-nav > li.dropdown > a:focus, .navbar-default .navbar-nav > li.dropdown > a:hover {
  color: white;
}

.navbar-default.navbar-fixed {
  position: fixed;
  background-color: #c8d700;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  border-radius: 0;
}

.navbar-right .dropdown-menu {
  right: -1px;
  left: auto;
  top: 48px;
}

.dropdown-menu > li > a {
  padding: 12px 18px;
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

.navbar-toggle {
  margin-right: 5px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

ul.nav.navbar-nav.navbar-right {
  padding: 12px 20px 12px 15px;
  margin-top: 0;
}

.navbar-fixed ul.nav.navbar-nav.navbar-right {
  margin-top: 0;
}

.navbar-fixed .navbar-toggle {
  margin-top: 0;
}

.navbar-default .navbar-toggle {
  border-color: white;
}

div#navbar {
  margin-top: 28px;
}

.navbar-fixed div#navbar {
  margin-top: 0px;
}

.nav .fb {
  position: relative;
  top: -6px;
}

.nav {
  z-index: 999;
}

ul.dropdown-menu {
  background: #c8d700;
  font-size: 13px;
}

.aboutPageTop {
  background: url(../../img/bg-about.jpg) center center no-repeat;
  background-size: cover;
  height: 450px;
  position: relative;
}

.aboutPageTop h1 {
  font-size: 50px;
  font-family: now_altlight, sans-serif;
  bottom: 15px;
  position: absolute;
  left: 15%;
}

.aboutPageTop h1 span {
  font-family: 'now_altbold', sans-serif;
}

.aboutPage {
  margin: 40px auto;
  /* Common style */
  /* Anchor will cover the whole item by default */
  /* For some effects it will show as a button */
  /*-----------------*/
  /***** Goliath *****/
  /*-----------------*/
}

.aboutPage .boss {
  font-family: "Open Sans", sans-serif;
  color: #2b2b2b;
  font-size: 15px;
}

.aboutPage .boss h2 {
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.aboutPage .signature {
  margin: 30px auto;
}

.aboutPage .img img, .aboutPage .signature img {
  object-fit: cover;
  height: auto;
  max-width: 100%;
}

.aboutPage .text {
  margin-right: 5%;
}

.aboutPage .text p {
  margin-top: 15px;
  text-align: justify;
  text-indent: 20px;
}

.aboutPage .team {
  margin: 40px auto;
}

.aboutPage .team h3 {
  font-size: 30px;
  font-family: now_altlight, sans-serif;
}

.aboutPage .team h3 span {
  font-family: 'now_altbold', sans-serif;
}

.aboutPage .team .box {
  margin: 20px auto;
}

.aboutPage .team .box img {
  width: 100%;
  object-fit: cover;
}

.aboutPage .box figure {
  position: relative;
  float: left;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  margin: 0;
}

.aboutPage .box figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
}

.aboutPage .grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.aboutPage .grid figure figcaption::before,
.aboutPage .grid figure figcaption::after {
  pointer-events: none;
}

.aboutPage .grid figure figcaption,
.aboutPage .grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aboutPage .grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.aboutPage .grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.aboutPage .grid figure h2 span {
  font-weight: 800;
}

.aboutPage .grid figure h2,
.aboutPage .grid figure p {
  margin: 0;
}

.aboutPage .grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

.aboutPage figure.effect-goliath {
  background: #a20290;
  margin-bottom: 50px;
}

.aboutPage figure.effect-goliath img,
.aboutPage figure.effect-goliath h2 {
  transition: transform 0.35s;
}

.aboutPage figure.effect-goliath img {
  backface-visibility: hidden;
}

.aboutPage figure.effect-goliath h2,
.aboutPage figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  text-align: center;
}

.aboutPage figure.effect-goliath p {
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: translate3d(0, 50px, 0);
  color: white;
  font-size: 21px;
  padding-top: 10px;
  width: 98%;
  text-transform: uppercase;
}

.aboutPage figure.effect-goliath:hover img {
  transform: translate3d(0, -80px, 0);
}

.aboutPage figure.effect-goliath:hover h2 {
  transform: translate3d(0, -100px, 0);
}

.aboutPage figure.effect-goliath:hover p {
  opacity: 1;
  transform: translate3d(0, 20px, 0);
}

.contactPageTop {
  background: url(../../img/bg-contact.jpg) center center no-repeat;
}

.contactPage .locations {
  margin: 50px 0 50px 15%;
  text-transform: uppercase;
}

.contactPage .locations ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.contactPage .locations ul li {
  display: inline-block;
  font-family: 'now_altbold', sans-serif;
  font-size: 22px;
  color: #2b2b2b;
  cursor: pointer;
}

.contactPage .locations ul li span {
  color: #2b2b2b;
}

.contactPage .locations ul li:hover, .contactPage .locations ul li:focus {
  color: #d4d2ce;
}

.contactPage .locations ul li:hover span, .contactPage .locations ul li:focus span {
  color: #2b2b2b;
}

.contactPage .locations .active {
  color: #d4d2ce;
}

.contactPage .locations span {
  font-weight: bold;
  position: relative;
  top: -4px;
  margin: auto 10px;
}

.contactPage h2 {
  color: #2b2b2b;
  font-family: 'now_altbold', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
}

.contactPage .input {
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;
  vertical-align: top;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  display: block;
}

.contactPage .input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: bold;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}

.contactPage .input__field:focus {
  outline: none;
}

.contactPage .input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #6a7989;
  font-weight: bold;
  font-size: 70.25%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  user-select: none;
}

.contactPage .input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}

.contactPage .graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.contactPage .icon {
  color: #ddd;
  font-size: 150%;
}

.contactPage .input__field--isao {
  z-index: 10;
  padding: 0.75em 0.1em 0.25em;
  width: 100%;
  max-width: 100%;
  background: transparent;
  color: #afb3b8;
}

.contactPage .input__label--isao {
  position: relative;
  overflow: hidden;
  padding: 0;
  width: 100%;
  max-width: 100%;
  color: #545454;
  text-align: left;
}

.contactPage .input__label--isao::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 7px;
  background: #5a5a5a;
  transform: scale3d(1, 0.4, 1);
  transform-origin: 50% 100%;
  transition: transform 0.3s, background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.contactPage .input__label--isao::after {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.75em 0.15em;
  color: #c8d700;
  opacity: 0;
  transform: translate3d(0, 50%, 0);
  transition: opacity 0.3s, transform 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  pointer-events: none;
}

.contactPage .textarea {
  min-height: 30px;
  min-width: 100%;
}

.contactPage .input__field--isao:focus + .input__label--isao::before {
  background-color: #c8d700;
  transform: scale3d(1, 1, 1);
}

.contactPage .input__field--isao:focus + .input__label--isao {
  pointer-events: none;
}

.contactPage .input__field--isao:focus + .input__label--isao::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.contactPage .input__label-content--isao {
  padding: 0.75em 0.15em;
  transition: opacity 0.3s, transform 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.contactPage .input__field--isao:focus + .input__label--isao .input__label-content--isao {
  opacity: 0;
  transform: translate3d(0, -50%, 0);
}

.contactPage .alert-success {
  color: white;
  background-color: #c8d700;
  border-color: #c8d700;
}

.contactPage .alert {
  margin-top: 28px;
}

.contactPage button {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 17px 40px 12px;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  background: #c8d700;
  border: 1px solid transparent;
  margin: 20px auto;
  position: relative;
  transition: 0.3s;
}

.contactPage button:hover {
  color: #c8d700;
  background: #FDF9F0;
  border: 1px solid #c8d700;
  transition: 0.3s;
}

.contactPage .address {
  background: url(../../img/bg-address.jpg) center center;
  color: white;
  height: 546px;
}

.contactPage .address .view {
  display: none;
  position: relative;
  top: 118px;
  left: 8%;
  width: 310px;
}

.contactPage .address .view ul {
  padding: 0;
  list-style: none;
  margin-bottom: 35px;
}

.contactPage .address .view ul:first-child {
  text-transform: uppercase;
}

.contactPage .address .view ul .pointer {
  margin-bottom: 20px;
}

.contactPage .address .view ul li {
  font-family: now_altlight, sans-serif;
  font-size: 21px;
  margin-bottom: 5px;
}

.contactPage .address .view ul li b {
  font-family: 'now_altbold', sans-serif;
}

.contactPage .address .view.active {
  display: block;
}

.contactPage #maps {
  z-index: -1;
  position: relative;
}

.contactPage .gmaps {
  width: 100%;
  position: relative;
  display: none;
}

.contactPage .gmaps.current {
  display: block;
}

.contactPage .gmaps #map1, .contactPage .gmaps #map2, .contactPage .gmaps #map3, .contactPage .gmaps #map4 {
  height: 500px;
  width: 100%;
}

.contactPage .form {
  width: 100%;
  position: relative;
  margin: 45px auto 40px;
  padding: 0 8%;
}

.contactPage a {
  color: white;
}

.contactPage .a, .contactPage .b {
  width: 49.99999%;
  display: inline-block;
  float: left;
}

.contactPage .mainView {
  width: 100%;
  min-height: 546px;
}

.contactPage .title {
  font-size: 28px;
  font-family: 'now_altbold', sans-serif;
  font-weight: bold;
  margin-top: 50px;
  text-align: center;
}

.control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 200px;
  height: 210px;
  margin: 10px;
}

.control {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
}

.control span {
  display: block;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 1px;
  left: 4px;
  height: 15px;
  width: 15px;
  background: #fff;
  border: 1px solid #c8d700;
  border-radius: 3px;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.control:hover input ~ .control__indicator {
  background: #fff;
}

.control input {
  border-radius: 3px;
}

.control input:focus ~ .control__indicator {
  background: #fff;
}

.control input:checked ~ .control__indicator {
  background: #c8d700;
  border: 1px solid #c8d700;
}

.control:hover input:not([disabled]):checked ~ .control__indicator {
  background: #c8d700;
}

.control input:checked:focus ~ .control__indicator {
  background: #c8d700;
}

.control input:disabled ~ .control__indicator {
  background: #c8d700;
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control__indicator:after {
  display: block;
}

.control--checkbox .control__indicator:after {
  top: 1px;
  left: 1px;
  width: 11px;
  height: 11px;
  background: url(../../img/icon-check-white.png);
}

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #fff;
}

.control--checkbox.faded {
  opacity: 0.3;
  pointer-events: none;
}

.control--radio.faded {
  opacity: 0.3;
  pointer-events: none;
}

.control--radio input[type='radio'] {
  border-radius: 50%;
}

.control--radio .control__indicator:after {
  top: 8px;
  left: 4px;
  width: 19px;
  height: 14px;
}

.control--radio input:disabled ~ .control__indicator:after {
  background: #fff;
}

.offerPageTop {
  background: url(../../img/bg-offer.jpg) center center no-repeat;
  background-size: cover;
}

.offerTeenPageTop {
  background: url(../../img/bg-offer-teenagers.jpg) center center no-repeat;
  background-size: cover;
}

.offerHsgPageTop {
  background: url(../../img/bg-offer-adults.jpg) center center no-repeat;
  background-size: cover;
}

.offerPage {
  margin: 50px auto 0;
  font-family: "Open Sans", sans-serif;
}

.offerPage h2, .offerPage h3, .offerPage h4 {
  font-family: 'now_altbold', sans-serif;
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 0;
}

.offerPage .rocket {
  position: absolute;
  top: -150px;
}

.offerPage .rocket img {
  width: 100%;
}

.offerPage p {
  margin-top: 15px;
}

.offerPage .whySection .title {
  margin: 10px 10px;
}

.offerPage .whySection .img {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offerPage .a, .offerPage .b {
  width: 49.99999%;
  display: inline-block;
  float: left;
  height: 497px;
  margin-left: -1px;
}

.offerPage .a img, .offerPage .b img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.offerPage .text {
  margin: 7% 9%;
  max-width: 650px;
}

.offerPage .text .more {
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 0;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  position: relative;
  top: 10px;
  transition: 0.3s;
}

.offerPage .text .more:hover {
  color: #c8d700;
}

.offerPage .organization {
  background: #a20290;
  color: white;
}

.offerPage .organization a {
  color: white;
}

.offerPage .organization a:hover {
  color: #c8d700;
}

.offerPage h4 {
  color: white;
}

.offerPage ul {
  padding: 0;
  list-style: none url(../../img/icon-ok-small.png);
  list-style-position: inside;
}

.offerPage ul li {
  margin-bottom: 6px;
}

.offerPage ul li span {
  position: relative;
  top: -3px;
  left: 3px;
}

.offerPage .mainView {
  border-bottom: 1px solid #cecece;
  padding-bottom: 70px;
  margin-bottom: 100px;
}

.offerPage .levels {
  margin-top: 10px;
  list-style: none;
}

.offerPage .col-sm-6 {
  margin-bottom: 30px;
}

.offerPage .adults {
  list-style-position: outside;
  text-align: justify;
}

.offerPage .adults span {
  position: relative;
  top: -5px;
}

.blogPageTop {
  background: url(../../img/bg-blog.jpg) center center no-repeat;
  background-size: cover;
}

.blogPage .pagination {
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  position: relative;
}

.blogPage .pagination ul {
  padding: 0;
  list-style: none;
}

.blogPage .pagination ul li {
  display: inline-block;
  border-radius: 50px;
  border: none;
  padding-top: 9px;
  height: 35px;
  width: 35px;
  margin-right: 6px;
  transition: 0.5s;
}

.blogPage .pagination ul li:hover {
  background-color: #c8d700;
  transition: 0.5s;
}

.blogPage .pagination ul li:hover a {
  color: white;
}

.blogPage .pagination ul li a {
  padding: 10px;
  color: #98a1a2;
}

.blogPage .pagination ul .active {
  background-color: #c8d700;
}

.blogPage .pagination ul .active a {
  color: white;
  padding: 10px;
}

.blogPage .pagination img {
  width: auto;
}

.blogPage .pagination .left {
  position: absolute;
  top: 7px;
  left: 0;
  width: 30px;
  text-align: center;
  margin: auto;
  right: 180px;
}

.blogPage .pagination .right {
  position: absolute;
  top: 6px;
  right: -168px;
  text-align: center;
  margin: auto;
  width: 20px;
  left: 0;
}

.blogPage .blogSection.singleNews {
  border-bottom: 1px solid #cecece;
  padding-bottom: 30px;
}

.blogPage .blogSection .date span:first-child {
  font-size: 46px;
  line-height: 34px;
  margin-top: 11px;
}

.blogPage .blogSection .date span:nth-child(2) {
  font-size: 10px;
}

.blogPage .blogSection .date span:nth-child(3) {
  font-size: 20px;
}

.blogPage .content {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 50px;
}

.blogPage .content p {
  margin-top: 15px;
  font-size: 14px;
  text-align: justify;
}

.blogPage .content h2 {
  text-align: justify;
}

.blogPage .content span {
  display: block;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}

.blogPage .content img {
  width: 100%;
}

.blogPage .news-navigation {
  margin: 50px auto;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'now_altbold', sans-serif;
}

.blogPage .news-navigation .right {
  float: right;
}

.blogPage .news-navigation .left {
  float: left;
}

.blogPage .news-navigation .left, .blogPage .news-navigation .right {
  font-weight: 800;
  display: inline-block;
}

.blogPage .news-navigation .left a, .blogPage .news-navigation .right a {
  color: #c8d700;
  padding: 15px 26px 10px;
  border-radius: 30px;
  border: 1px solid #c8d700;
  background: transparent;
  transition: 0.3s;
  display: block;
}

.blogPage .news-navigation .left a:hover, .blogPage .news-navigation .right a:hover {
  background: #c8d700;
  color: white;
  transition: 0.3s;
}

.jobPage {
  margin: 50px auto;
}

.jobPage h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: now_altlight, sans-serif;
}

.jobPage h2 span {
  font-family: 'now_altbold', sans-serif;
}

.jobPage .box {
  margin: 50px auto 40px;
  width: 340px;
  height: 100%;
  border: 1px solid #c8d700;
  text-align: center;
  padding: 15px 0;
  font-family: 'now_altbold', sans-serif;
}

.jobPage .box h3 {
  font-size: 20px;
  color: #2b2b2b;
  text-transform: uppercase;
}

.jobPage .box h4 {
  font-size: 17px;
  text-transform: uppercase;
  color: #a20290;
}

.jobPage .box h4 a {
  color: grey;
  font-size: 13px;
  font-weight: 200;
}

.jobPage .box h4 a:hover {
  color: black;
}

.jobPage .requirements {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  background: url(../../img/bg-requirements.jpg) center center no-repeat;
  background-size: cover;
  min-height: 548px;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
}

.jobPage .requirements ul {
  margin: auto auto auto 20%;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 40%;
}

.jobPage .requirements ul li {
  list-style: none url(../../img/icon-ok-purple.png);
  padding-bottom: 10px;
}

.jobPage .requirements ul li span {
  position: relative;
  left: 3px;
  top: -1px;
}

.jobPage .requirements ul li:first-child {
  position: relative;
  right: 20px;
  font-family: 'now_altbold', sans-serif;
  font-weight: bold;
  list-style: none;
  text-transform: uppercase;
}

.jobPage .requirements .title {
  position: relative;
  text-align: center;
  top: 30px;
  font-family: 'now_altbold', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: 100px;
  margin: auto;
  padding-right: 38%;
}

.jobPage .object {
  position: absolute;
  top: -140px;
  right: 16%;
}

.jobPage .object img {
  max-width: 100%;
}

.jobPage .more .title {
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.jobPage .more a {
  color: black;
}

.jobPage .more p {
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
}

.jobPage .more p b {
  font-weight: bold;
}

.jobPage .text {
  font-family: "Open Sans", sans-serif;
}

.fePageTop {
  background: url(../../img/bg-fe.jpg) center center no-repeat;
  background-size: cover;
}

.fePage {
  margin: 50px auto;
}

.fePage h2, .fePage h4, .fePage h5 {
  color: #2b2b2b;
  font-family: 'now_altbold', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
}

.fePage h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #2b2b2b;
}

.fePage .fe {
  margin: 30px auto 40px;
}

.fePage .actions a {
  display: inline-block;
  font-family: 'now_altbold', sans-serif;
  text-transform: uppercase;
  padding: 18px 27px 14px;
  border-radius: 27px;
  color: white;
  font-size: 16px;
  background: #c8d700;
  transition: 0.3s;
  border: 1px solid #c8d700;
  margin-right: 20px;
  margin-bottom: 13px;
}

.fePage .actions a:hover {
  color: #c8d700;
  background: white;
  border: 1px solid #c8d700;
  transition: 0.3s;
}

.fePage .watch a {
  margin: 80px auto 40px;
  text-align: center;
  display: block;
  color: #2b2b2b;
  font-family: 'now_altbold', sans-serif;
  font-size: 45px;
  text-transform: uppercase;
  transition: 0.4s;
  background: #fff;
  padding: 50px;
}

.fePage .watch a span {
  position: relative;
  top: 5px;
  left: 7px;
}

.fePage .watch a img {
  transition: 0.3s;
}

.fePage .watch a:hover span {
  color: #5f5f5f;
}

.fePage .watch a:hover img {
  transition: 0.3s;
  transform: rotate(360deg);
}

.fePage .img img, .fePage .fe img {
  pointer-events: none;
  max-width: 100%;
}

.fePage .aboutMethod {
  margin-top: 50px;
}

.fePage .aboutMethod .actions {
  margin-top: 10px;
  padding: 12px 22px 11px;
  text-align: center;
}

.fePage .aboutMethod .actions a {
  color: white;
}

.fePage .aboutMethod .actions a:hover {
  color: #c8d700;
}

.fePage h4 {
  text-align: center;
  margin-bottom: 40px;
}

.fePage h5 {
  text-align: left;
  font-size: 20px;
  margin-bottom: 30px;
}

.fePage .text {
  padding: 0 3%;
}

.fePage ul {
  text-align: left;
  padding: 0;
  list-style: none url(../../img/icon-ok-small.png);
}

.fePage ul li {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.fePage ul li span {
  position: relative;
  top: -3px;
  left: 3px;
}

.fePage .moreFacts {
  position: relative;
  padding: 70px 20%;
  margin: 80px auto;
  color: white;
  background: url(../../img/bg-fe-purple.jpg) center center;
  background-size: cover;
}

.fePage .moreFacts ul li {
  font-family: 'now_altbold', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}

.fePage .quote .title, .fePage .quote .author span {
  font-family: 'now_altbold', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.fePage .quote .content, .fePage .quote .about {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #2b2b2b;
}

.fePage .quote .quoteArea {
  background: url(../../img/bg-quote.png) no-repeat;
  height: 447px;
  width: 455px;
  padding: 40px 50px 30px 35px;
  margin: auto;
}

.fePage .quote .quoteArea .content {
  text-align: justify;
}

.fePage .quote .quoteArea .title {
  text-align: justify;
}

.fePage .quote .about {
  margin-top: 15px;
  font-size: 12px;
}

.fePage .quote .author span {
  position: relative;
  top: 35px;
  left: -4px;
}

.fePage .authoritiesSection {
  margin-top: 40px;
  text-align: justify;
}

.fePage .authoritiesSection .title {
  font-family: 'now_altbold', sans-serif;
  font-size: 21px;
  text-transform: uppercase;
  margin: 40px auto 20px;
}

.fePage .authoritiesSection .title.last {
  text-align: right;
}

.fePage .authoritiesSection .description {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.fePage iframe {
  margin: 50px auto;
  text-align: center;
}

@media all and (max-width: 767px) {
  .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    color: #555;
    background-color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #a20290;
  }
  .navbar-default .navbar-nav > li.dropdown > a:focus, .navbar-default .navbar-nav > li.dropdown > a:hover {
    color: #2b2b2b;
  }
  .navbar-fixed div#navbar {
    margin-top: 20px;
  }
  ul.nav.navbar-nav.navbar-right {
    background-color: #c8d700;
    z-index: 9999;
    position: relative;
  }
  .navbar-default.navbar-fixed {
    height: 76px;
    margin-top: 0;
  }
  .navbar-fixed .logo {
    margin-left: 10px;
  }
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    margin-top: 28px;
  }
  .newsletterSection {
    padding: 20px 4%;
  }
  .newsletterSection input {
    width: 100%;
  }
  .newsletterSection button {
    margin-left: 0;
    margin-top: 13px;
    margin-bottom: 17px;
  }
  .aboutSection .counters .experience {
    top: -70px;
    right: 249px;
  }
  .aboutSection .counters .experience b {
    top: 28px;
    right: -189px;
    font-size: 26px;
  }
  .blogSection .box {
    padding: 0;
  }
  footer .logo {
    width: 100%;
  }
  footer .list, footer .logo {
    display: block;
    float: none;
  }
  footer .list {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 4px;
  }
  footer .row {
    margin: auto 2%;
  }
  footer .copyrights {
    margin: 20px 0;
  }
  .aboutPage .box figure {
    float: none;
    max-width: 300px;
    margin: auto;
  }
  .aboutPageTop h1 {
    left: 5%;
  }
  .offerPage .rocket {
    display: none;
  }
  .offerPage .whySection .col-sm-4 {
    margin-bottom: 30px;
  }
  .offerPage .text {
    margin: -10px 9%;
    max-width: 650px;
    padding-top: 38px;
    padding-bottom: 20px;
  }
  .whySection {
    padding-bottom: 40px;
  }
  .offerPage .whySection .title {
    margin: 10px 10%;
  }
  .offerPage .a, .offerPage .b {
    width: 49.99999%;
    min-height: 497px;
    margin-left: -1px;
  }
  .a.full-img, .b.full-img {
    height: 470px;
  }
  footer .copyrights .rights, footer .copyrights .authors {
    width: 47.7%;
  }
}

@media (min-width: 1400px) {
  .blogSection .container {
    width: 1400px;
  }
  .activitiesSection .container {
    width: 1400px;
  }
  .blogPage .blogSection .container.singleNews {
    width: 1170px;
  }
  .activitiesSection ul {
    height: 200px;
  }
  .blogPage .blogSection .title {
    width: 75%;
  }
  .blogSection .title {
    width: 80%;
  }
  .blogPage .singleNews .title {
    width: 90%;
  }
}

@media all and (min-width: 767px) and (max-width: 992px) {
  .aboutSection .counters .partner {
    position: absolute;
    top: 135px;
    right: 0;
  }
  .aboutSection .counters .img {
    position: relative;
    left: -74px;
  }
  .aboutSection .counters .department {
    right: 0;
    top: 46px;
  }
  .aboutSection .counters .experience {
    font-size: 23px;
    top: -70px;
    right: 160px;
  }
  .aboutSection .counters .experience b {
    right: -223px;
    font-size: 30px;
  }
  footer .list {
    width: 32%;
    margin-left: 30px;
  }
  footer .contact {
    margin: 11px auto;
  }
}

@media all and (min-width: 768px) and (max-width: 841px) {
  ul.nav.navbar-nav.navbar-right {
    padding: 0;
    margin-top: 30px;
  }
  .topSection .logo {
    width: 166px;
    top: 50px;
  }
  .topSection .logo img {
    width: 90%;
  }
  .navbar-fixed .logo {
    top: 2px;
  }
}

@media all and (max-width: 850px) {
  .contactPage .a, .contactPage .b {
    width: 100%;
    display: block;
    float: none;
  }
  .offerPage .a, .offerPage .b {
    width: 100%;
    display: block;
    float: none;
  }
  .contactPage .a {
    margin-bottom: 80px;
  }
  .contactPage .locations {
    margin: 50px 4%;
  }
}

@media all and (max-width: 1309px) {
  .jobPage .object {
    display: none;
  }
  .jobPage .requirements ul {
    margin: auto;
    padding: 40px 10%;
    width: 100%;
  }
}

@media all and (min-width: 1310px) and (max-width: 1846px) {
  .jobPage .box {
    margin: 50px auto 40px;
  }
  .jobPage .object {
    right: 2%;
  }
}

@media all and (max-width: 992px) {
  .activitiesSection .titleBox {
    width: 100%;
    text-align: center;
  }
  .activitiesSection .second {
    position: absolute;
    left: 65%;
    top: -16px;
  }
  .activitiesSection .last {
    position: absolute;
    left: 49%;
    top: -15px;
    margin: auto;
    right: 0;
  }
  .activitiesSection .header {
    margin-left: 5%;
  }
  .activitiesSection .title {
    margin-right: 13%;
  }
  .activitiesSection .title.first {
    margin-right: 0;
  }
  .blogSection .title {
    width: 100%;
    margin-left: 0;
  }
  .fePage .quote .author {
    margin-top: 20px;
  }
}

@media all and (min-width: 767px) {
  .navbar-default.navbar-fixed {
    height: 77px;
  }
}

@media all and (max-width: 500px) {
  .activitiesSection .title.first {
    width: 100%;
  }
  .topSection .actions a {
    display: block;
    text-align: center;
    margin: auto 10% 20px;
  }
  .topSection .actions a:first-child {
    margin-right: 10%;
  }
  .topSection .view {
    position: absolute;
    top: 300px;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    margin-top: 48px;
  }
  .topSection .top-nav ul {
    text-align: center;
  }
  .opinionsSection ul {
    top: 130px;
  }
  .aboutSection .counters .partner {
    position: absolute;
    top: 135px;
    right: 0;
  }
  .aboutSection .counters .img {
    position: relative;
    left: -74px;
  }
  .aboutSection .counters .department {
    right: 0;
    top: 30px;
  }
  .aboutSection .counters .experience {
    right: 0;
    left: 0;
  }
  .aboutSection .counters .experience b {
    top: 31px;
    right: -162px;
    font-size: 23px;
  }
  .activitiesSection .last, .activitiesSection .second, .activitiesSection .hours {
    position: relative;
    text-align: center;
    right: 0;
    top: 20px;
    margin: auto;
    left: 0;
    display: block;
  }
  .activitiesSection .titleBox {
    width: 100%;
    text-align: left;
  }
  .activitiesSection .title {
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
  }
  .blogSection .title {
    width: 70%;
  }
  .topSection .top-nav ul {
    padding: 0;
  }
  .contactPage .form {
    width: auto;
    margin: auto 5%;
  }
  .aboutPageTop h1 {
    font-size: 30px;
    font-family: now_altlight, sans-serif;
  }
  footer .copyrights .rights, footer .copyrights .authors {
    width: 100%;
    display: block;
    text-align: center;
  }
  .contactPage .gmaps #map {
    height: 400px;
    width: 100%;
  }
  .contactPage .input {
    width: calc(100% - 0em);
  }
  .jobPage .box {
    width: 100%;
    padding-top: 7px;
  }
  .offerPage .a, .offerPage .b {
    height: 100%;
  }
  .offerPage .a.full-img, .offerPage .b.full-img {
    height: 470px;
  }
  .whySection .description {
    margin: auto 1%;
  }
  .aboutPage .team .box img {
    height: 200px;
  }
  .aboutPage .team .box {
    height: 200px;
  }
  .aboutPage figure.effect-goliath h2, .aboutPage figure.effect-goliath p {
    bottom: 19px;
    padding: 12px;
  }
  .singleNews .title {
    margin-left: 10px;
  }
  .fePage .quote .quoteArea {
    background: none;
    width: 100%;
    height: 100%;
    margin: 0 auto 40px;
    border: 2px solid #a20290;
    border-radius: 50px;
  }
  .singleNews .title {
    width: 69%;
  }
}

@media all and (max-width: 430px) {
  .aboutSection .counters .department {
    top: 90px;
  }
  .aboutSection .counters .partner {
    top: 189px;
    text-align: center;
    line-height: 25px;
  }
  .aboutSection .counters .experience b {
    top: 31px;
    right: -157px;
    font-size: 21px;
  }
}

@media all and (min-width: 992px) and (max-width: 1200px) {
  .activitiesSection .title {
    text-align: left;
  }
  .activitiesSection .hours {
    left: -65px;
    transform: scale(0.8);
  }
  .activitiesSection .second {
    position: absolute;
    left: 200px;
    top: -20px;
  }
  .activitiesSection .last {
    position: absolute;
    left: 219px;
    top: -15px;
  }
  .blogSection .title {
    width: 70%;
    margin-left: 0;
  }
  .fePage .quote .quoteArea {
    background: none;
    width: 100%;
    height: 100%;
    margin: 0 auto 40px;
    border: 2px solid #a20290;
    border-radius: 50px;
  }
}

@media all and (max-width: 1450px) {
  .offerPage .whySection .title {
    height: 57px;
    margin: 10px auto !important;
  }
}

@media all and (max-width: 1400px) {
  .activitiesSection .title {
    font-size: 23px;
  }
}

@media all and (max-width: 1200px) {
  .activitiesSection .title {
    font-size: 19px;
  }
  .activitiesSection ul {
    min-height: 310px;
  }
}

@media all and (max-width: 991px) {
  .activitiesSection ul {
    min-height: auto;
  }
}

@media all and (max-width: 900px) {
  .offerPage .whySection .title {
    font-size: 17px;
  }
}

@media all and (max-width: 767px) {
  .offerPage .whySection .title {
    height: auto;
  }
}

.justify-box {
  text-align: justify;
  text-indent: 20px;
}
